import React, { useState } from 'react'
import { Grid, DisplayItem, Modal } from '../generic'
import { printCurrency, printDate } from '../../lib/helpers'
import SalesInteractionUpdate from '../sales-interactions/SalesInteractionUpdate'
import EditIcon from '@material-ui/icons/Edit';

// TODO: Add PropTypes

const PolicyInformation = (props) => {
  const [salesInteractionModal, setSalesInteractionModal] = useState(false)
  const { creditlife: { id, policyNumber, payment, coverAmount, quoteAcceptedDate, applicationDate, termsVersion, productType, signupSalesInteraction, accountingIdentifier }, quote = true, setProductType, refetchQuery } = props

  let salesInteraction;
  if (signupSalesInteraction) {
    if (signupSalesInteraction.salesPerson) {
      salesInteraction = `${signupSalesInteraction.salesPerson.first} ${signupSalesInteraction.salesPerson.last}`;
    } else {
      salesInteraction = 'SALES';
    }
  } else if (accountingIdentifier) {
    salesInteraction = accountingIdentifier;
  } else {
    salesInteraction = 'DIGITAL SALE';
  }

  return (
    <>
      <h2>Product Information</h2>
      <Grid container justifyContent='flex-start' alignItems='center' spacing={1}>
        <DisplayItem label="Product Type" value={productType} xs={2} />
        <DisplayItem label="Terms Version" value={termsVersion} xs={2} />
        <DisplayItem label="Applied" value={printDate(applicationDate)} xs={2} />
        <DisplayItem label="Accepted" value={printDate(quoteAcceptedDate)} xs={2} />
        <DisplayItem label="Cover Amount" value={printCurrency(coverAmount)} xs={2} />
        <DisplayItem label="Payment" value={printCurrency(payment)} xs={2} />
       <> <DisplayItem label="Sold By" value={salesInteraction} xs={2}  onClick={() => signupSalesInteraction && setSalesInteractionModal(true)} /> <EditIcon style={{ fontSize: 12, cursor: 'pointer' }} onClick={() => signupSalesInteraction && setSalesInteractionModal(true)} />  </>
      </Grid>

      <Modal title={'Update Sales Person'}  okText="Ok" cancelText="Back" open={salesInteractionModal}  onCancel={() => setSalesInteractionModal(false)} maxWidth='md'>
      <SalesInteractionUpdate salesInteractionId={signupSalesInteraction && signupSalesInteraction.id} setOpen={setSalesInteractionModal} creditlifeId={id} {...props}  />
      </Modal>
      <br />
    </>
  )
}

export default PolicyInformation
// < h1 > { quote? 'Quote': 'Policy' } Information</h1 >
