import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import useCollapse from 'react-collapsed';
import WhatsAppChat from '../../communication/WhatsAppChat'
import { Grid } from '../../generic';

const useStyles = makeStyles({
  button: {
    '&: hover': {
      backgroundColor: '#f00f15',
      color: '#fff',
    }
  }
})

let ClientWhatsApp = ({ messages, clientId, from, clientInfo }) => {
  const classes = useStyles();
  const [isExpanded, setExpanded] = useState(true);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  useEffect(() => {
    setExpanded(messages.length > 0);
  }, [messages])

  function handleOnClick() {
    setExpanded(!isExpanded);
  }

  return (<>
    <div className="collapsible">
      <div className="header" {...getToggleProps({ onClick: handleOnClick })}>
        <Grid container spacing={1}>
          <Grid item xs={11} style={{ fontVariant: 'small-caps', fontSize: 21, fontWeight: 400 }}>
            WhatsApp
          </Grid>
          <Grid item xs={1} style={{ fontSize: 14, fontWeight: 400, color: 'blue' }} >
            {isExpanded ? 'Hide' : 'Show'}
          </Grid>
        </Grid>
      </div>
      <div {...getCollapseProps()}>
        <WhatsAppChat messages={messages && messages.length > 0 && messages.sort((a, b) => (a.createdAt < b.createdAt) ? 1 : -1)} clientId={clientId} from={from} clientInfo={clientInfo} />
      </div>
    </div>
  </>)
}

export default ClientWhatsApp