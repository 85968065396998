import React, { useState } from 'react'
import gql from 'graphql-tag';
import GraphQLTable from '../generic/GraphQLTable';
import { withApollo } from 'react-apollo'
import { blankProfile } from '../../assets/images';

const config = {
  query: gql`
    {
      employees(limit: 1000) {
        id
        employeeNumber
        first
        last
      }
    }
  `,
  dataset: data => data.employees,
  fields: {
    name: {
      label: 'Name',
      accessor: row => row.last ? `${row.last}, ${row.first}` : null,
    },
    photo: {
      label: 'Profile Picture',
      noHeading: true,
      Cell: row => {
        if (row.value && row.value.url) {
          return (
            <img
              className="img-circle"
              style={{ width: 30, height: 30 }}
              src={row.value.url}
            />
          );
        } else
          return (
            <img
              className="img-circle"
              style={{ width: 30, height: 30 }}
              src={blankProfile}
            />
          );
      },
    },
  },
  defaultSorted: [{ id: 'name' }]
};


const UPDATE_SALES_PERSON = gql`
  mutation updateSalesInteraction( $id: String!, $input: UpdateSalesInteractionInput!) {
    updateSalesInteraction(id: $id, input: $input) {
      id
    }
  }`

function SalesInteractionUpdate({ client, salesInteractionId, setOpen, refetchQuery, creditlifeId}) {    
  const [snackOpen, setSnackOpen] = useState(false)
  const [snackSeverity, setSnackSeverity] = useState()
  const [snackText, setSnackText] = useState()

  const updateSalesInteraction = (employeeId) => {
    client.mutate({
      mutation: UPDATE_SALES_PERSON,
      variables: {
        id: salesInteractionId,
        input:{
          salesPersonId: employeeId
        }
      },
      refetchQueries: [{ query: refetchQuery, variables: { id: creditlifeId } }]
    }).then(() => {
      setOpen(false);
    })
    .catch((error) => {
      console.error("Error updating sales interaction: ", error);
    });
  }

 return <GraphQLTable config={config} onRowPress={employee => updateSalesInteraction(employee.id)} />
}

SalesInteractionUpdate = withApollo(SalesInteractionUpdate)
export default SalesInteractionUpdate;
