import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag';
import { makeStyles } from '@material-ui/core/styles';
import { graphql } from 'react-apollo';
import BankInfo from './BankInfo'
import { Select, Grid, Card, Button, ActivityIndicator } from '../generic';

const useStyles = makeStyles({
  start: {
    flexGrow: 1,
    justifyContent: 'flex-start',
  },
  end: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    paddingRight: 21,
  }
});

const GET_CLIENT_BANKS = gql`
  query GetClientBanks( $id: String!) {
    client(id: $id) {
      id
      bankAccounts {
        id
        bankName
        branchCode
        accountNo
        ownAccount
        accountHolderName
        accountHolderContactNumber
        accountHolderIdNumber
        accountHolderEmail
        idNumberMatch
        nameMatch
        phoneMatch
        accountExists
        avsRawData
      }
  }
}
`

const getBankAccountOptions = (bankAccounts) => {
  let banks = []
  bankAccounts && bankAccounts.forEach(account => {
    let associated = ' '
    if (account.bankName !== 'UNKNOWN' && account.accountNo !== 'UNKNOWN') {
      let associatedCount = 0
      if (account.associatedWith && account.associatedWith.length > 0) {
        account.associatedWith.forEach(a => {
          if (a.type && ["CREDITLIFE", "FUNERAL", "SAVINGS"].indexOf(a.type.toUpperCase()) > -1) {
            associatedCount++
          }
        })
      }
      if (associatedCount == 1) { associated = `, ${associatedCount} association` }
      if (associatedCount > 1) { associated = `, ${associatedCount} associations` }
      let bank = { label: account.bankName + ' ' + account.accountNo + associated, value: account.id }

      banks.push(bank)
    }
  })
  banks.push({ label: 'Add new bank account', value: "NEW" })
  return banks
}

function SelectBankAccount({ clientId, bankId, cancel, updateBank, data }) {
  const classes = useStyles();

  const { error, loading, client } = data
  const { bankAccounts } = client || {}

  const [bank, setBank] = useState(bankId)
  const [bankOptions, setBankOptions] = useState()
  const [showNew, setShowNew] = useState(bankAccounts && bankAccounts.length === 0 ? true : false)
  const [cleaned, setCleaned] = useState()

  if (loading) {
    return <ActivityIndicator />
  }

  if (error) {
    return <h1>{error.toString()}</h1>
  }

  const onChange = (v) => {
    console.log('Select Bank oncahnge', v) 
      setBank(v);
      setShowNew(v === "NEW")
  }

  console.log('Select Bank Account bank is', bank) 
  return (
    <Card>
      {!showNew ? <>
        <Grid container className={classes.start} direction='column' spacing={1} >
          <Grid item xs={8}>
            <Select
              label='Select Bank Account'
              value={bank}
              options={getBankAccountOptions(bankAccounts)}
              onChange={(e) => {
                console.log('Select Bank Account', e);
                onChange(e.target.value)
              }}
            />
          </Grid>
        </Grid>

        <Grid container className={classes.end} spacing={1} >
          <Grid item width={2}>
            <Button color="secondary" onClick={cancel}> Cancel</Button>
          </Grid>
          <Grid item>
            <Button color="primary" disabled={(bank && bankId === bank) || (bank && bankId === bank) || !bank || !bank === undefined || (bank === "NEW")}
              onClick={() => {
                updateBank(bankAccounts.find(b => b.id.toString() == bank.toString()))
              }}> Save</Button>
          </Grid>
        </Grid>
        </>
          : <>
          <Grid container className={classes.start} spacing={1} >
            <h2>Add Bank account</h2> 
            <br />
            <Grid item xs={12}><BankInfo clientId={clientId} id={null} cancel={cancel} setBank={(bank) => updateBank(bank)} /> </Grid>
          </Grid >

        </>}
    </Card>)
}

SelectBankAccount = graphql(GET_CLIENT_BANKS, { options: ({ clientId }) => ({ variables: { id: clientId } }) })(SelectBankAccount)
export default SelectBankAccount