import React, { useState, useEffect } from 'react';
import { graphql, withApollo } from 'react-apollo';
import { makeStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag'
import TextField from '@material-ui/core/TextField';
// import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
// import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import useCollapse from 'react-collapsed';
import { format } from 'date-fns'
import { MaterialTable, Grid, Button, DisplayFile } from '../../generic';

const useStyles = makeStyles({
  button: {
    '&: hover': {
      backgroundColor: '#f00f15',
      color: '#fff',
    }
  }
})

const CLIENT_NOTES = gql`
  query Client($id: String!) {
    client(id: $id) {
      id
      firstNames
      surname
      idNumber
      notes {
        id
        text
        by
        date
        tags
        filesIds
        associatedWith {
          type
          id
        }
        createdBy {
          description
        }
      }
    }
  }
`

const CREATE_NOTE = gql`
	mutation($input: CreateNoteInput! ) {
		createNote(input: $input) {
      id
      text
      tags
      filesIds
      associatedWith {
        type
        id
      }
      by
      date
      updatedBy {
        description
      }
      createdBy {
        description
      }
		}
	}
`;

const UPDATE_NOTE = gql`
	mutation($id: String!, $input: UpdateNoteInput! ) {
		updateNote(id: $id, input: $input) {
      id
      text
      associatedWith {
        type
        id
      }
      filesIds
      by
      date
      updatedBy {
        description
      }
      createdBy {
        description
      }
		}
	}
`;



let ClientNotes = (props) => {
  const { clientId, data, query, variables, type, productId, client, displayNotes = true, tag, ...other } = props
  const { client: clientData } = data || {}
  const { notes: clientNotes } = clientData || {}
  const classes = useStyles();
  const [clientInfo, setClientInfo] = useState();
  const [text, setText] = useState();
  const [noteId, setNoteId] = useState();
  const [fileId, setFileId] = useState();
  const [notes, setNotes] = useState([]);
  const [isExpanded, setExpanded] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  useEffect(() => {
    // if (productId) {
    //   setNotes(clientNotes.filter(n => {
    //     let found = false
    //     n.associatedWith.forEach(ass => {
    //       if (ass.id === productId) {
    //         found = true
    //       }
    //     })
    //     return found
    //   }))
    //   setExpanded(notes.length > 0)
    // }
    // else {
      setNotes(clientNotes)
      setExpanded(clientNotes.length > 0)
    // }
    if (data && data.client) {
      const { id, firstNames, surname, idNumber } = data.client
      setClientInfo({ id, firstNames, surname, idNumber })
    }
  }, [data]);

  useEffect(() => {
    setExpanded(notes.length > 0)
  }, [notes]);

  function handleOnClick() {
    // Do more stuff with the click event!
    // Or, set isExpanded conditionally 
    setExpanded(!isExpanded);
  }

  const cols = [
    {
      Header: 'Tags',
      accessor: 'tags',
    },
    {
      Header: 'Note',
      accessor: 'text',
    },
    {
      Header: 'By',
      accessor: row => row.by ? row.by : row.createdBy ? row.createdBy.description : row.updatedBy ? row.updatedBy.description : '',
    },
    {
      Header: 'When',
      id: 'date',
      accessor: (row) => format(row.date, 'dd-LL-yyyy HH:mm')
    },
    {
      Header: 'Attachment',
      accessor: 'filesIds[0]',
      id: 'file2',
      Cell: ({ value }) => {
        return value ? <DisplayFile fileId={value} noButtons={true} clientInfo={clientInfo} /> : ''
      }
    },
  ]

  const createNote = (input) => {
    client
      .mutate({
        mutation: CREATE_NOTE,
        variables: {
          input: { ...input },
        },
        refetchQueries: query ? [{ query, variables }] : undefined
      })
      .then(res => {
        let newNotes = []
        notes.forEach(n => newNotes.push(n))
        newNotes.push(res.data.createNote)
        setNotes(newNotes)
        setText(null)
        // console.log('client notes ', res)
      })
      .catch(err => {
        console.log('create note errors  ', err)
      });
  }

  const updateNote = (input) => {
    client
      .mutate({
        mutation: UPDATE_NOTE,
        variables: {
          input: { ...input },
          id: noteId
        },
        refetchQueries: query ? [{ query, variables }] : undefined
      })
      .then(res => {
        let newNotes = []
        notes.forEach(n => {
          if (n.id === res.data.updateNote) {
            newNotes.push(res.data.updateNote)
          }
          else {
            newNotes.push(n)
          }
        })
        setNotes(newNotes)
        setText(null)
      })
      .catch(err => {
        console.log('update errors  ', err)
      });
  }

  const handleClick = (text) => {
    // console.log('client notes handleClick text', text)

    if (text) {
      let associatedWith = [{ type: 'Client', id: clientId }]
      if (type !== 'Client') {
        associatedWith.push({ type, id: productId })
      }
      if (noteId) {
        updateNote({ text, tags: [tag], filesIds: [fileId], associatedWith })
      }
      else {
        createNote({ text, tags: [tag], filesIds: [fileId], associatedWith })
      }
      setNoteId()
    }
  };

  const onChange = (e) => {
    setText(e.target.value)
    if (!e.target.value) {
      setNoteId()
    }

  };

  if (data.loading) return <p>Loading...</p>;

  return (
    <div style={{ marginTop: "7px", width: "100%" }}>
      <div className="collapsible">
        <div className="header" {...getToggleProps({ onClick: handleOnClick })}>
          <Grid container spacing={1}>
            <Grid item xs={11} style={{ fontVariant: 'small-caps', fontSize: 21, fontWeight: 400 }}>
              {productId ? type ? `${type} Notes` : 'Notes' : 'Client Notes'}
            </Grid>
            <Grid item xs={1} style={{ fontSize: 14, fontWeight: 400, color: 'blue' }} >
              {isExpanded ? 'Hide' : 'Show'}
            </Grid>
          </Grid>
        </div>
        <div  {...getCollapseProps()} style={{ overflow: 'scroll' }}>
          <Grid container spacing={1}>
            <Grid item xs={10}>
              <TextField id="noteinput" fullWidth value={text} onChange={onChange}
                onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleClick(text);
                }
              }} />
            </Grid>
            <Grid item xs={1}>
              <DisplayFile fileId={fileId} onChange={setFileId} noUpdate={true} clientInfo={clientInfo} />
            </Grid>
            <Grid item xs={1}>
              <Button size='small' aria-label="edit" disabled={!text} onClick={() => handleClick(text)} >
                Save
              </Button>
            </Grid>
          </Grid>
          <br />
          <MaterialTable
            columns={cols}
            data={notes.sort((a, b) => (a.date < b.date) ? 1 : -1)}
            download={false}
            clientInfo={clientInfo}
            onRowPress={(row) => { setNoteId(row.id); setText(row.text) }} />
          <br />
        </div>
      </div>

    </div>)
}


const graphqlLoadingHOC = Comp => props => {

  if (props.data.loading) {
    return <div />
  }

  if (props.data.error) {
    return <h1>{props.data.error.toString()}</h1>
  }

  // console.log('client Note props ', props.data)

  return <Comp {...props} />
}


ClientNotes = withApollo(ClientNotes)
ClientNotes = graphqlLoadingHOC(ClientNotes)
ClientNotes = graphql(CLIENT_NOTES, {
  options: ({ match, clientId }) => ({ variables: { id: match && match.params ? match.params.clientId : clientId } })
})(ClientNotes)
export default ClientNotes = withApollo(ClientNotes)