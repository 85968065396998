import React, { useState, useEffect } from 'react';
import { Grid, Card, Button, FormModal, ActivityIndicator, DisplayItem, TextInput } from '../generic'
import { printCurrency } from '../../lib/helpers'
import { withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import { ClientInformation, ClientDocuments, ClientNotes } from '../shared/client'
import ProductCommunication from '../communication/ProductCommunication'
import ClientProducts from './ClientProducts'
import ClientFuneral from './ClientFuneral'
import ClientCreditLife from './ClientCreditLife'
import ClearanceCertificate from './ClearanceCertificate'
import ClientClaims from './ClientClaims'
import { AuthConsumer } from 'react-auth-helper';
import { isValidCellphoneNumber, isValidEmail } from '../../lib/validations';
import Numeral from 'numeral'
import 'numeral/locales/en-za.js'
// switch between locales
Numeral.locale('en-za')

const TOTALCOVER = gql`
	query TotalCover($idNumber: String!) {
  getTotalCover(idNumber:$idNumber)
}`

const GET_CLIENT_BY_ID = gql`
  query client( $id: String!) {
    client(id: $id) {
        id
        idNumber
        idType
        firstNames
        surname
        cellNumbers
        email
        createdAt
        bankAccounts {
          id
          bankName
          accountNo
          associatedWith {
            id
            type
            reference
          }
        }
        clearanceCertificates {
          id
          certificateNumber
          state
          updatedAt
          amount
        }
        creditLifePolicies {
          id
          policyNumber
				  productType
          # paymentType
				  coverAmount
				  payment
				  policyStatus
          updatedAt
				}
        funeralPolicies {
          id
          policyNumber
          policyType
          paymentMethod
          paymentDueDay
          currentPremiumTotal
          coverAmount
          createdAt
          updatedAt
          state
        }
        savings {
          id
          totalUnits {
            unitType
            unitPrice
            amount
            value
          }
        }
        claims {
          id
          claimNumber
          claimType
          productType
          amountClaimed
          effectiveDate
          createdAt
        }
    }
  }`

const GET_CLIENT = gql`
  query GetClientDetails( $filter: ClientFilter!) {
    clients(filter: $filter) {
        id
        idNumber
        idType
        firstNames
        surname
        cellNumbers
        email
        createdAt
        bankAccounts {
          id
          bankName
          accountNo
          associatedWith {
            id
            type
            reference
          }
        }
        clearanceCertificates {
          id
          certificateNumber
          state
          updatedAt
          amount
        }
        creditLifePolicies {
          id
          policyNumber
				  productType
          paymentType
				  coverAmount
				  payment
				  policyStatus
          updatedAt
				}
        funeralPolicies {
          id
          policyNumber
          policyType
          paymentMethod
          paymentDueDay
          currentPremiumTotal
          coverAmount
          createdAt
          updatedAt
          state
        }
        savings {
          id
          totalUnits {
            unitType
            unitPrice
            amount
            value
          }
        }
        claims {
          id
          claimNumber
          claimType
          productType
          amountClaimed
          effectiveDate
          createdAt
        }
    }
  }`


export const CLIENT_PRODUCTS = gql`
	query ClientProducts($searchString: String!) {
		clientProducts(searchString: $searchString) {
    		id
        firstNames
        surname
        idType
        idNumber
        passportExpiresDate
        email
        cellNumbers
        smoker
        occupation
        acceptedTerms
        createdAt
        employers {
          company{
            name
            }
          companyName
          isPartTime
        }
        income
        estimatedIncome
        cellNumbers
        bankAccounts {
          id
          bankName
          accountNo
          associatedWith {
            id
            type
            reference
          }
        }
        clearanceCertificates {
          id
          certificateNumber
          state
          updatedAt
          amount
        }
        creditLifePolicies {
          id
          policyNumber
				  productType
          paymentType
				  coverAmount
				  payment
				  policyStatus
          updatedAt
				}
        funeralPolicies {
          id
          policyNumber
          policyType
          paymentMethod
          paymentDueDay
          currentPremiumTotal
          coverAmount
          createdAt
          updatedAt
          state
          livesCovered {
            id
            firstNames
            surname
            idNumber
            memberType
            currentPremium
            coverAmount
            ageAtSignup
            cellNumbers
            email
            memberType
            currentPremium
            coverAmount
            income
            estimatedIncome
            incomeBand
            confidence
          }
        }
        savings {
          id
          totalUnits {
            unitType
            unitPrice
            amount
            value
          }
        }
        claims {
          id
          claimNumber
          claimType
          productType
          amountClaimed
          effectiveDate
          createdAt
        }
        creditRecord {
          fetchedAt
        }
        createdAt
			}
		}
	`

export const EditModal = (props) => {
  return (
    <FormModal
      okText="Ok"
      cancelText="Back"
      width='99%'
      disableCancel={true}
      disableOk={true}
      componentProps={props}
      style={{ top: 10 }}
      small
    />
  )
}

const DisplayModal = (props) => {
  return (
    <FormModal
      okText="Ok"
      cancelText="Back"
      width='98%'
      style={{ top: 10 }}
      componentProps={props}
    />
  )
}

const getDetails = (client, searchString, setClientData, setSpinner, setErrorText, setClients) => {
  setClients()
  setClientData()
  setErrorText(null)
  if (searchString) {
    setSpinner(true)
    let filter
    if (!isValidCellphoneNumber(searchString)) {
      filter = { cellNumbers: searchString }
    }
    else if (!isValidEmail(searchString)) {
      filter = { email: searchString }
    }
    if (filter) {
      client.query({ query: GET_CLIENT, variables: { filter } })
        .then(data => {
          setSpinner(false)
          if (data.data.clients) {
            setClients(data.data.clients)
          }
        })
    }
    else {
    client
      .query({ query: CLIENT_PRODUCTS, variables: { searchString } })
      .then(data => {
        setSpinner(false)
        if (data.data.clientProducts) {
          setClientData({ ...data.data.clientProducts })
        } else {
          setErrorText("No records found")
        }
      })
    }
  }
}

const Product = ({ client, match, history, clientId, ...props }) => {
  const [clientData, setClientData] = useState()
  const [open, setOpen] = useState(clientId ? false : true)
  const [clients, setClients] = useState()
  const [searchString, setSearchString] = useState()
  const [spinner, setSpinner] = useState(false)
  const [errorText, setErrorText] = useState(null)

  useEffect(() => {
    setSearchString(match && match.params && match.params.idNumber ? match.params.idNumber : '')
  }, [match])

  useEffect(() => {
    if (clientId) {
      client.query({ query: GET_CLIENT_BY_ID, variables: { id: clientId } })
        .then(data => {
          setSpinner(false)
          if (data.data.client) {
            setClientData(data.data.client)
            setOpen(false)
          }
          else {
            setClients()
          }
        })
    }
    else {
      getDetails(client, searchString, setClientData, setSpinner, setErrorText, setClients)
    }
  }, [searchString, clientId]);

  const runSearch = () => {
    setClientData()
    setClients()
    getDetails(client, searchString, setClientData, setSpinner, setErrorText, setClients)
  }

  console.log(' Products clientData', clientData)

  return (
    <AuthConsumer>
      {({ inGroup }) => (
        <Card>
          {/* {false && open && <>
            <Grid container spacing={1} >
            <Grid item xs={4} >
              <TextInput label='ID, Product, Cell or Email' fullWidth disabled={spinner} value={searchString} placeholder="Enter an ID, Product number, Cell or Email" id="searchString" inputProps={{ style: { textTransform: "uppercase" } }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    runSearch()
                  }
                }}
                onChange={e => setSearchString(e.target.value)} />
            </Grid>
            <Grid item xs={2} ><Button onClick={() => runSearch()} >SEARCH</Button></Grid>
            <Grid item xs={2} > {spinner && <ActivityIndicator center={false} />}</Grid>
            </Grid>
            <br />
          </>} */}
          {spinner && <ActivityIndicator center={false} />}
          {errorText}
          {clientData && !clients &&
            <>
              <ClientInformation id={clientData.id} refetchQuery={CLIENT_PRODUCTS} clientData={clientData} history={history} productId={searchString} />

              {clientData.creditLifePolicies && clientData.creditLifePolicies.length > 0 && <>
              <>
                <h2> Credit Life Products </h2>
                  <ClientCreditLife {...clientData} />
              </>
              </>}

              {clientData.funeralPolicies && clientData.funeralPolicies.length > 0 && <>
              <>
                <br />
                <h2> Funeral Products </h2>
                <ClientFuneral {...clientData} />
              </>

            </>}
            
            {clientData.clearanceCertificates && clientData.clearanceCertificates.length > 0 && <>
              <>
                <h2>Clearance Certificats</h2>
                <ClearanceCertificate {...clientData} />
              </>
            </>}

              {clientData.savings && inGroup("savings") && <div onClick={() => history.push(`/savings/${clientData.savings.id}/edit`)}>
              <>
                <br />
                <h2>Savings Account</h2>
                  <Grid container justifyContent='flex-start' alignItems='center' spacing={1} >
                    <DisplayItem label="Total" value={printCurrency(clientData.savings.totalUnits.reduce((total, u) => total + (u.value || 0), 0))} xs={2} />
                    <DisplayItem label="Liquid Fund" value={printCurrency(clientData.savings.totalUnits.filter(u => u.unitType === "LIQUID_FUND").reduce((total, u) => total + (u.value || 0), 0))} xs={2} />
                    <DisplayItem label="Cash Fund" value={printCurrency(clientData.savings.totalUnits.filter(u => u.unitType === "CASH_FUND").reduce((total, u) => total + (u.value || 0), 0))} xs={2} />
                  </Grid>
              </>
              </div>
            }

            {clientData.claims && (clientData.claims.length > 0) && <>
              <br />
              <h2>Claims</h2>
              <ClientClaims {...clientData} />
            </>}

            {clientData && <ClientNotes clientId={clientData.id} type="Client" />}

            {clientData && <ProductCommunication clientId={clientData.id}  />}

            {clientData && <ClientDocuments clientId={clientData.id} />}

            </>}

          {clients && <ClientProducts clients={clients} runSearch={runSearch} />}
        </Card>)}
    </AuthConsumer>
  );
}
// export default compose(withApollo)(Product)
export default withApollo(Product)