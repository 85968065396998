import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { useQuery, withApollo } from 'react-apollo'
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Button, TextInput, Select, Grid } from '../generic'
import { CLIENT_COMMUNICATIONS } from '../shared/client/ClientCommunications'

const useStyles = makeStyles((theme) => ({
  footer: {
    paddingRight: 0,
    paddingBottom: 21
  },
  button: {
    "& .MuiButton-label": {
      minWidth: "150px!important",
    }
  },
}));

const GET_CLIENT = gql`
  query Client($id: String!) {
    client(id: $id) {
      id
      email
      cellNumbers
      firstNames
      surname
    }
  }
`

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CREATE_COMMUNICATION = gql`
	mutation CreateCommunication($input: CreateCommunicationInput!) {
		createCommunication(input: $input) {
			id
		}
	}
`

const toHHMMSS = (value) => {
  var sec_num = parseInt(value, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  var seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours < 10) { hours = "0" + hours; }
  if (minutes < 10) { minutes = "0" + minutes; }
  if (seconds < 10) { seconds = "0" + seconds; }
  return hours + ':' + minutes + ':' + seconds;
}

const prettyPhone = (number) => {

  if (number.startsWith('+')) {
    return number.substr(0, 2) + ' ' + number.substr(2, 3) + ' ' + number.substr(6, 3) + ' ' + number.substr(9, 4)
  }
  else return number
}

const SendSms = ({ clientId, funeralId, creditLifeId, history, ...props }) => {
  const { data, loading, error } = clientId && useQuery(GET_CLIENT, { variables: { id: clientId } }) || {}
  const [open, setOpen] = useState(false)
  const classes = useStyles();
  const [message, setMessage] = useState()
  const [to, setTo] = useState()
  const { client } = data || {}
  const { firstNames, surname, email, cellNumbers } = client || {}


  useEffect(() => {
    setTo(cellNumbers && cellNumbers[0])
    setMessage(`Hi ${firstNames ? firstNames : ''}`)
  }, [client]);

  const createCommunication = (input) => {
    props.client.mutate({
      mutation: CREATE_COMMUNICATION,
      variables: {
        input
      },
      refetchQueries: [{ query: CLIENT_COMMUNICATIONS, variables: { id: clientId } }]
    })
      .then(response => {
        setOpen(false);
      })
      .catch(error => {
        console.log('there was an error sending the query', error)
      })
  }

  const onSend = () => {
    <Snackbar open={true} autoHideDuration={6000} >
      <Alert severity="info">
        `Sending SMS to ${firstNames} ${surname} @ ${prettyPhone(to)}`
      </Alert>
    </Snackbar >

    const input = { clientId, type: 'SMS', message, to }

    if (funeralId) {
      input.funeralId = funeralId
    }
    if (creditLifeId) {
      input.creditLifeId = creditLifeId
    }

    createCommunication(input)
  }

  if (loading) { return <div>Loading...</div> }

  return (
    <>
      <div style={{ fontSize: 14, fontWeight: 400, color: 'blue' }} onClick={(data) => setOpen(true)} >
        SMS
      </div >

      <Dialog aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="xl"
        onClose={() => setOpen(false)}
        open={open}
      >
        <DialogContent>
          <Grid container spacing={2} justifyContent='flex-start'>
            <Grid item xs={3}>
              <Select options={cellNumbers && cellNumbers.map(f => ({ 'value': f, 'label': f }))} label={`To`} fullWidth value={to} placeholder={`To`} id="toCell"
                onChange={e => setTo(e.target.value)} />
            </Grid>
          </Grid>
          <br />
          <TextInput label={`Message`} fullWidth value={message} placeholder={`Subject`} id="message"
            onChange={e => setMessage(e.target.value)} />
        </DialogContent>
        <DialogActions className={classes.footer}>
          <Button className={classes.button} onClick={() => setOpen(false)} color="secondary" variant="contained">
            Cancel
          </Button>
          <Button disabled={!to || !message} className={classes.button} onClick={() => {
            onSend();
            setOpen(false);
          }}
            color="primary" variant="contained" style={{ marginRight: 20 }}>
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default withApollo(SendSms);